class Tab {
  constructor(element) {
    this._element = element;

    // Get relevant elements and collections
    this._tablist = element.querySelector('ul');
    this._tabs = this._tablist.querySelectorAll('a');
    this._panels = element.querySelectorAll('[id^="section"]');

    this._setup();
  }

  _setup() {
    // Add the tablist role to the first <ul> in the .tabbed container
    this._tablist.setAttribute('role', 'tablist');

    // Add semantics are remove user focusability for each tab
    Array.prototype.forEach.call(this._tabs, (tab, i) => {
      tab.setAttribute('role', 'tab');
      tab.setAttribute('id', `tab${i + 1}`);
      tab.setAttribute('tabindex', '-1');
      tab.parentNode.setAttribute('role', 'presentation');

      // Handle clicking of tabs for mouse users
      tab.addEventListener('click', (e) => {
        e.preventDefault();
        const currentTab = this._tablist.querySelector('[aria-selected]');
        if (e.currentTarget !== currentTab) {
          this.switchTab(currentTab, e.currentTarget);
        }
      });

      // Handle keydown events for keyboard users
      tab.addEventListener('keydown', (e) => {
        // Get the index of the current tab in the tabs node list
        const index = Array.prototype.indexOf.call(this._tabs, e.currentTarget);
        // Work out which key the user is pressing and
        // Calculate the new tab's index where appropriate
        const dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
        if (dir !== null) {
          e.preventDefault();
          // If the down key is pressed, move focus to the open panel,
          // otherwise switch to the adjacent tab
          dir === 'down' ? panels[i].focus() : this._tabs[dir] ? switchTab(e.currentTarget, this._tabs[dir]) : void 0;
        }
      });
    });

    // Add tab panel semantics and hide them all
    Array.prototype.forEach.call(this._panels, (panel, i) => {
      panel.setAttribute('role', 'tabpanel');
      panel.setAttribute('tabindex', '-1');
      // const id = panel.getAttribute('id');
      panel.setAttribute('aria-labelledby', this._tabs[i].id);
      panel.hidden = true;
    });

    // Initially activate the first tab and reveal the first tab panel
    this._tabs[0].removeAttribute('tabindex');
    this._tabs[0].setAttribute('aria-selected', 'true');
    this._panels[0].hidden = false;
  }


  // The tab switching function
  switchTab(oldTab, newTab) {
    newTab.focus();
    // Make the active tab focusable by the user (Tab key)
    newTab.removeAttribute('tabindex');
    // Set the selected state
    newTab.setAttribute('aria-selected', 'true');
    oldTab.removeAttribute('aria-selected');
    oldTab.setAttribute('tabindex', '-1');
    // Get the indices of the new and old tabs to find the correct
    // tab panels to show and hide
    const index = Array.prototype.indexOf.call(this._tabs, newTab);
    const oldIndex = Array.prototype.indexOf.call(this._tabs, oldTab);
    this._panels[oldIndex].hidden = true;
    this._panels[index].hidden = false;
  }
}

const tabs = document.querySelectorAll('[data-tabs]');
tabs.forEach((element) => {
  new Tab(element);
});

export default Tab;
