export const setupLazyLoading = () => {
  const lazyImages = [].slice.call(document.querySelectorAll('img[loading]'));
  const lazyVideos = [].slice.call(document.querySelectorAll('video[loading]'));

  if ('loading' in HTMLImageElement.prototype || !('IntersectionObserver' in window)) {
    // Simple fallback for images
    lazyImages.forEach((entry) => {
      entry.src = entry.dataset.src;
      if (entry.dataset.srcset) {
        entry.srcset = entry.dataset.srcset;
      }
    });

    // Simple fallback for videos
    lazyVideos.forEach((video) => {
      for (const source in video.childNodes) {
        if (Object.prototype.hasOwnProperty.call(video.childNodes, source)) {
          const videoSource = video.childNodes[source];

          if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
            videoSource.src = videoSource.dataset.src;
          }
        }
      }

      video.load();
    });
  } else {
    const lazyImageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;

          lazyImage.src = lazyImage.dataset.src;

          if (lazyImage.dataset.srcset) {
            lazyImage.srcset = lazyImage.dataset.srcset;
          }

          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });
    const lazyVideoObserver = new IntersectionObserver((entries) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          for (const source in video.childNodes) {
            if (Object.prototype.hasOwnProperty.call(video.childNodes, source)) {
              const videoSource = video.childNodes[source];

              if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                videoSource.src = videoSource.dataset.src;
              }
            }
          }

          video.target.load();
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyImages.forEach((lazyImage) => {
      lazyImageObserver.observe(lazyImage);
    });

    lazyVideos.forEach((lazyVideo) => {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
};
