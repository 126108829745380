export class Navigation {
  constructor(el) {
    this.navbar = el;
    this.tablist = this.navbar.querySelector('.navbar__items');
    this.tabs = this.navbar.querySelectorAll('a[data-target]');
    this.panels = this.navbar.querySelectorAll('.navbar__dropdown');

    this.tabs.forEach((anchor) => {
      const target = anchor.dataset.target;
      const targetEl = document.getElementById(target);
      const heading = targetEl.querySelector('h3');
      const closeButton = targetEl.querySelector('button');

      anchor.setAttribute('role', 'button');
      anchor.setAttribute('aria-expanded', false);
      anchor.setAttribute('aria-controls', target);

      // Make heading focusable
      heading.setAttribute('tabindex', '-1');

      closeButton.addEventListener('click', () => {
        this.closeMega(anchor, targetEl);
      });

      anchor.addEventListener('click', (e) => {
        e.preventDefault();
        this.toggleMega(anchor, targetEl);
      });
    });
  }

  toggleMega(element, targetEl) {
    if (element.getAttribute('aria-expanded') === 'true') {
      this.closeMega(element, targetEl);
    } else {
      this.closeAllMegas();
      this.showMega(element, targetEl);
    }
  }

  showMega(anchor, targetEl) {
    anchor.setAttribute('aria-expanded', true);
    targetEl.classList.add('show');
    targetEl.querySelector('h3').focus();
  }

  closeMega(anchor, targetEl) {
    anchor.setAttribute('aria-expanded', false);
    targetEl.classList.remove('show');
    anchor.focus();
  }

  closeAllMegas() {
    this.tabs.forEach((parent) => {
      parent.setAttribute('aria-expanded', false);
    });
    this.panels.forEach((mega) => {
      mega.classList.remove('show');
    });
  }
}
