export const setupSliders = () => {
  /* touch detection */
  window.addEventListener('touchstart', function touched() {
    document.body.classList.add('touch');
    window.removeEventListener('touchstart', touched, false);
  }, false);

  const sliders = document.querySelectorAll('.slider');

  for (let i = 0; i < sliders.length; i++) {
    const slider = sliders[i];

    // lazy loading and button controls
    const gallery = slider.querySelector('.slider__gallery');
    const slides = gallery.querySelectorAll('.slider__item');
    const instructions = slider.querySelector('.slider__instructions');

    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      if (slide.querySelector('a')) {
        slide.querySelector('a').setAttribute('tabindex', '-1');
      }
    }

    const observerSettings = {
      root: gallery,
      rootMargin: '-10px',
    };

    if ('IntersectionObserver' in window) {
      const scrollIt = function(slideToShow) {
        const scrollPos = Array.prototype.indexOf.call(slides, slideToShow) * (gallery.scrollWidth / slides.length);
        gallery.scrollLeft = scrollPos;
      };

      const showSlide = function(dir) {
        const visible = slider.querySelectorAll('[aria-label="gallery"] .visible');
        const i = dir === 'previous' ? 0 : 1;

        if (visible.length > 1) {
          scrollIt(i === 0 ? visible[0].previousElementSibling : visible[0].nextElementSibling);
        } else {
          const newSlide = i === 0 ? visible[0].previousElementSibling : visible[0].nextElementSibling;
          if (newSlide) {
            scrollIt(newSlide);
          }
        }
      };

      const callback = function callback(slides) {
        for (let i = 0; i < slides.length; i++) {
          const entry = slides[i];
          const link = entry.target.querySelector('a');

          entry.target.classList.remove('visible');
          if (link) {
            link.setAttribute('tabindex', '-1');
          }
          if (!entry.intersectionRatio > 0) {
            return;
          }
          const img = entry.target.querySelector('img');
          if (img.dataset.src) {
            img.setAttribute('src', img.dataset.src);
            img.removeAttribute('data-src');
          }
          entry.target.classList.add('visible');
          if (link) {
            link.removeAttribute('tabindex', '-1');
          }
        }
      };

      const observer = new IntersectionObserver(callback, observerSettings);
      for (let i = 0; i < slides.length; i++) {
        observer.observe(slides[i]);
      }

      const controls = document.createElement('ul');
      controls.setAttribute('aria-label', 'gallery controls');
      controls.classList.add('slider__controls');
      controls.innerHTML = `
        <li>
          <button type="button" aria-label="previous">
            <svg aria-hidden="true" focusable="false" class="icon">
              <use xlink:href="/assets/icons.svg#i-arrow_back"></use>
            </svg>
          </button>
        </li>
        <li>
          <button type="button" aria-label="next">
            <svg aria-hidden="true" focusable="false" class="icon">
              <use xlink:href="/assets/icons.svg#i-arrow_forward"/>
            </svg>
          </button>
        </li>
      `;
      slider.insertBefore(controls, instructions.nextElementSibling);
      slider.classList.add('slider--with-arrows');

      controls.addEventListener('click', (e) => {
        showSlide(e.target.closest('button').getAttribute('aria-label'));
      });

      gallery.addEventListener('keydown', (e) => {
        if (e.keyCode === 37) {
          e.preventDefault();
          showSlide('previous');
        } else if (e.keyCode === 39) {
          e.preventDefault();
          showSlide('next');
        }
      });
    } else {
      for (let i = 0; i < slides.length; i++) {
        const img = slides[i].querySelector('img');
        img.setAttribute('src', img.getAttribute('data-src'));
      }
    }
  }
};
